import { ValuerowComponent } from './valuerow.component';
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-valuerowdouble',
    templateUrl: './valuerow.component.html',
    styleUrls: ['./valuerow.component.scss'],
    standalone: false
})

export class DoubleValuerow extends ValuerowComponent {

	public value: number;

	constructor(
		public el: ElementRef,
		public translate: TranslateService,
		renderer: Renderer2
	) {
		super(el, translate, renderer);
	}

	public setValue(value: number) {
		this.value = value;
	}

	formatValue(): string {
		return this.value.toString();
	}
}