import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DownloadManagerService } from '../download-manager.service';

@Component({
    selector: 'app-download-manager-popup',
    templateUrl: './download-manager-popup.component.html',
    styleUrls: ['./download-manager-popup.component.scss'],
    host: {
        "[class]": "hostClass"
    },
    standalone: false
})
export class DownloadManagerPopupComponent implements OnInit, OnDestroy {
	env = environment;
	private subscription: Subscription;
	/*@HostBinding('class')*/ public hostClass = "hide";//: object = {};

	constructor(
		public downloadManager: DownloadManagerService
	) {
		this.subscription = this.downloadManager.showDownloadWindow.subscribe(_ => {
			this.hostClass = "showanimated";
		});
	}

	public hide() {
		this.hostClass = "hide";
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
	}
}
