import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

class Source {
  constructor(type : string, ext : string) {
    this.type = type;
    this.ext = ext;
  }

  sizes : string;
  srcset : string;
  type : string;
  ext: string;
}

@Component({
    selector: 'app-multi-format-image',
    templateUrl: './multi-format-image.component.html',
    styleUrls: ['./multi-format-image.component.scss'],
    standalone: false
})
export class MultiFormatImageComponent implements OnInit {
  private p_env = environment;

  private p_IMAGE_SIZES = ["300", "600", "900", "1600", ""];

  public loading : string = "lazy";
  public sources : Source[] = [];
  private p_src : string = "";
  private p_sizeCategory : string = "";

  @Input()
  set src(value: string) {
    this.p_src = value;

    let file = this.p_src;
    
    if(this.p_src.at(-1) !== ")")
      file = this.p_src.split('.').slice(0, -1).join('.');
    else
      this.p_src += ".jpg";

    for (const source of this.sources) {
      var srcset = "";
      for (const size of this.p_IMAGE_SIZES) {
        if(srcset != "")
          srcset += ", ";

        var imgFile = file + "." + source.ext;
  
        if(size != "")
          srcset += imgFile + "?width=" + size + " " + size + "w";
        else
          srcset += imgFile + " 2000w";
      }

      source.srcset = srcset;
    }
  }

  get src() {
    return this.p_src;
  }

  @Input()
  set disableLazyLoading(_: string) {
    this.loading = "eager";
  }

  @Input("class")
  public cssClass : string = "";

  @Input("sizecat")
  set sizeCategory(value: string) {
    this.p_sizeCategory = value;

    let sizes : string = "";

    switch(this.p_sizeCategory ) {
      case "large":
        sizes = "(min-width: 1600px) 1600px, (min-width: 1200px) 900px, (min-width: 992px) 600px, (min-width: 600px) 100px, (max-width: 600px) 100px";
        break;
      case "medium":
        sizes = "(min-width: 1200px) 900px, (min-width: 992px) 600px, (min-width: 600px) 100px, (max-width: 600px) 100px";
        break;
      case "small":
        sizes = "100px";
        break;
      default:
        sizes = "100vw";
        break;
    }

    for (const source of this.sources) {
      source.sizes = sizes;
    }
  }

  @Input()
  public alt : string = "";

  constructor() {
    for (const format of this.p_env.imageFormats) {
      this.sources.push(new Source(format.mime, format.ext));
    }
  }

  ngOnInit() {
  }
}
