import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[valuerow]',
    standalone: false
})
export class ValueRowDirective {

	constructor(public valuerowref: ViewContainerRef) {

	}

}
