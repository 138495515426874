import { ValuerowComponent } from './valuerow.component';
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-valuerowtranslate',
    templateUrl: './valuerow.component.html',
    styleUrls: ['./valuerow.component.scss'],
    standalone: false
})

export class TranslateValuerow extends ValuerowComponent {
	public value: string;

	constructor(
		public el: ElementRef,
		public translate: TranslateService,
		renderer: Renderer2
	) {
		super(el, translate, renderer);
	}

	public setValue(value: string) {
		this.value = value;
	}

	formatValue(): string {
		return this.testMap.get(Number(this.result.resultID)) + this.value;
	}
}
