import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FilterSelector } from 'src/app/shared/models/FilterSelector';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { CompareService } from 'src/app/core/services/compare.service';
import { AvailableSearchParameter, DetailCompare } from '@esders/connect-api';

@Component({
    selector: 'app-filteritem',
    templateUrl: './filteritem.component.html',
    styleUrls: ['./filteritem.component.scss'],
    standalone: false
})
export class FilteritemComponent implements OnInit {
	@Input() comparer: DetailCompare;

	@Output() remove = new EventEmitter<DetailCompare>();

	// tslint:disable-next-line: no-inferrable-types
	DataType: number;
	values: FilterSelector[];
	Comparers: string[];
	ASP: AvailableSearchParameter[] = [];

	private subscription: any;
	selectedFilter = new FormControl();

	constructor(
		private Service: CompareService,
		public translate: TranslateService) {
		this.values = [];
		this.selectedFilter.valueChanges.subscribe(res => this.onResultFieldChange(res));

		if (!this.Service.isLoaded) {
			this.subscription = this.Service.onChange.subscribe(_ => {
				this.subscription.unsubscribe();
				this.loadData();
			});
		}
	}

	ngOnInit(): void {

	}

	compareFN(a: any, b: any) {
		return a == b;
	}

	ngAfterViewInit(): void {
		if (this.Service.isLoaded)
			setTimeout((_: any) => this.loadData(), 500);	// Ich hasse web!!!!
	}

	onRemoveASP(asp: AvailableSearchParameter): void {
		this.Service.DeleteSearchParameter(asp);
	}

	onRemoveClick(): void {
		this.remove.emit(this.comparer);
	}

	/**
	 * Lädt die Daten für den Filter.
	 */
	private loadData(): void {
		this.ASP = this.Service.ASP;

		let sel = this.ASP.filter(e => e.measType == this.comparer.measPart && e.dataType == this.comparer.dataType && e.field == this.comparer.key);
		if (sel.length > 0)
			this.selectedFilter.setValue(sel[0]);
		else if (this.ASP.length > 0)
			this.selectedFilter.setValue(this.ASP[0]);
	}

	/**
	 * Übersetzt die enums
	 */
	private SetEnumSelection(type: number): void {
		switch (type) {
			case 13:
				{
					this.values = [];
					const evaluation = this.translate.instant("evaluation");
					for (const [key, _] of Object.entries(evaluation)) {
						if (key !== undefined && Number(key) !== 6) {
							this.values.push({ id: Number(key), translate: "evaluation." + key });
						}
					}
					if (this.comparer.value == "")
						this.comparer.value = this.values[0].id.toString();
				} break;
		}
	}

	/**
	 * Wird jedesmal aufgerufen, wenn das Feld Eigenschaften geänder wird.
	 * @param selected
	 */
	private onResultFieldChange(selected: AvailableSearchParameter): void {
		this.comparer.measPart = selected.measType;
		this.comparer.dataType = selected.dataType;
		this.comparer.key = selected.field;

		this.DataType = selected.dataType;
		this.Comparers = this.Service.GetCompareTypes(selected.dataType);

		switch (this.DataType) {
			case 0:
				{
					this.values = [];
					this.values.push({ id: 1, translate: "true" });
					this.values.push({ id: 0, translate: "false" });

					this.comparer.value = "1";
				} break;

			case 4:
				{
					this.SetEnumSelection(Number(selected.field));
				} break;
		}
	}
}
