import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AuthService } from '@esders/authentificate';
import { Menu } from '@esders/connect-api';
import { TranslateService } from "@ngx-translate/core";
import { MeasurementService } from 'src/app/core/services/measurement.service';

@Component({
    selector: 'app-measurement-search-bar',
    templateUrl: './measurement-search-bar.component.html',
    styleUrls: ['./measurement-search-bar.component.scss'],
    standalone: false
})
export class MeasurementSearchBarComponent implements OnInit {
	public addFilterEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	public downloadCollectionReport: boolean = false;

	@Input() hasMap : boolean = false;
	@Output() toggleMap : EventEmitter<Boolean> = new EventEmitter();

	public slideText: string = 't_cards_view';

	constructor(
		public measService: MeasurementService,
		public authService: AuthService
	) { }

	ngOnInit() {
	}

	public compareFN(a: any, b: any) {
		return a == b;
	}

	public toggleArchive(event : MatCheckboxChange) {
		this.measService.detailSearch.value.archived = event.checked;
		this.measService.locationSearch.value.archived = event.checked;
		this.refresh();
	}

	public enddatechange(event: any) {
		this.measService.detailSearch.value.endDate = event.value;
		this.measService.locationSearch.value.endDate = event.value;
	}

	public startdatechange(event: any) {
		this.measService.detailSearch.value.startDate = event.value;
		this.measService.locationSearch.value.startDate = event.value;
	}

	public clearAll() {
		this.measService.clear();
	}

	public refresh() {
		this.measService.updateMesurements(); 
		this.checkCollectionReport()
	}

	public checkCollectionReport() {
		if ([88, 69, 109, 111, 112, 19].includes(Number.parseInt(this.measService.detailSearch.value.menuId)))
			this.downloadCollectionReport = true;
		else
			this.downloadCollectionReport = false;
	}
}


@Pipe({
    name: 'orderByMenu',
    standalone: false
})
export class OrderByMenuPipe implements PipeTransform {

	constructor(
		public translate: TranslateService,
	) { }

	transform(array: Array<Menu>): Array<Menu> {

		if (!array || array === undefined || array.length === 0) {
			return [];
		}

		var menuTranslations: any;
		this.translate.get("menu").subscribe(val => {
			menuTranslations = val;
		});

		array.sort((a: Menu, b: Menu) => {
			let aText = menuTranslations[a.menuId] ?? "zz";
			let bText = menuTranslations[b.menuId] ?? "zz";

			// "Alle Messunge immer an den Anfang der Liste"
			if (a.menuId == -1) {
				return -10;
			} else if (b.menuId == -1) {
				return 10;
			} else {
				return aText.localeCompare(bText);
			}
		});
		return array;
	}

}