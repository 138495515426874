<mat-card style="height:380px">
	<mat-card-header>
		<mat-card-title>
			{{title | translate}}
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
			<table mat-table [dataSource]="datasource">
				<ng-container matColumnDef="details">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<a class="icon-link link-left" routerLinkActive="active" [routerLink]="element.details">
							<mat-icon>info</mat-icon>
						</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="1">
					<th mat-header-cell *matHeaderCellDef class="column"> {{displayColumns[0] | translate}} </th>
					<td mat-cell *matCellDef="let element"> {{element.nummer}} </td>
				</ng-container>

				<ng-container matColumnDef="2">
					<th mat-header-cell *matHeaderCellDef class="column"> {{displayColumns[1] | translate}} </th>
					<td mat-cell *matCellDef="let element"> {{element.date | date: ("t_date_format" | translate)}} </td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="nameColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: nameColumns;"></tr>
			</table>
	</mat-card-content>
</mat-card>
