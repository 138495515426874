import { Component, OnInit } from '@angular/core';
import { ShoppingCardService } from 'src/app/core/services/shopping-card.service';

@Component({
    selector: 'app-shopping-card-fab',
    templateUrl: './shopping-card-fab.component.html',
    styleUrls: ['./shopping-card-fab.component.scss'],
    host: {
        "[class]": "hostClass"
    },
    standalone: false
})
export class ShoppingCardFabComponent implements OnInit {
	public elementCount : number = 0;
	public offerId : number = 0;

	/*@HostBinding('class')*/ public hostClass ="hide";//: object = {};

  	constructor(
		  private _shoppingCard : ShoppingCardService) {
		this._shoppingCard.currentOffer.subscribe(res => {
			if(res?.elements.length > 0) {
				this.offerId = res.offerId;
				this.hostClass = "show";
			}
			else
				this.hostClass = "hide";

			this.elementCount = res?.elements.length;
		});
	}

  	ngOnInit() {
  	}

}
