import { Component, input, Input } from '@angular/core';
import { MeasurementService } from 'src/app/core/services/measurement.service';

@Component({
    selector: 'app-report-choose-multi-download',
    templateUrl: './report-choose-multi-download.component.html',
    styleUrls: ['./report-choose-multi-download.component.scss'],
    standalone: false
})
export class ReportChooseMultiDownloadComponent {
	@Input() public downloadCollectionReport: boolean = false;
	button = input(false);


	constructor(public measService: MeasurementService) { }
}
